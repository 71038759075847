/* eslint-disable max-len */
// Node Modules
import PropTypes from 'prop-types';
import * as React from 'react';
import styled from 'styled-components';

// Components
import JoinUsFormWrapper from '../components/form/presentational/JoinUsFormWrapper';
import Layout from '../components/core/layout';
import SEO from '../components/core/seo';

// Style
import { space } from '../style/design-system';

const NotFoundPage = ({ className }) => (
  <Layout displayStickyButton={false}>
    <SEO title="404: Not found" />
    <div className={className}>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h1>404: Page Not Found</h1>
            <p>We can&#39;t seem to find what you&#39;re looking for. Perhaps you&#39;re interested in joining our care community?</p>
            <JoinUsFormWrapper hideCourses />
          </div>
        </div>
      </div>
    </div>
  </Layout>
);

export default styled(NotFoundPage)`
  padding: ${space.h} 0;
`;

NotFoundPage.propTypes = {
  className: PropTypes.string.isRequired,
};
